<template>
  <div class="school-choice">
    <div class="school-choice__block-wrap">
      <div
        class="school-choice__block"
        v-for="item in mySchoolsData"
        :key="item"
        @click="
          selectSchool(
            item.id,
            item.is_subscription_enabled,
            item.name,
            item.code,
            item.logo
          )
        "
      >
        <SchoolLogo v-if="item.logo === ''" />
        <img v-else :src="item.logo" />
        <div class="school-choice__school-title">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { defineComponent } from "vue";
import SchoolLogo from "@/assets/svg/school-logo.svg?inline";

export default defineComponent({
  name: "SchoolChoiceSection",
  data() {
    return {
      mySchoolsData: {},
    };
  },
  components: {
    SchoolLogo,
  },
  mounted() {
    this.getMySchools();
  },
  methods: {
    selectSchool(id, is_subscription_enabled, name, code, logo) {
      localStorage.setItem("admin-school-id", id);
      // is_subscription_enabled change true
      localStorage.setItem("is-subscription-enabled", is_subscription_enabled);
      this.$router.push("/admin/analytics/home");
      localStorage.setItem(
        "sra-school",
        JSON.stringify({ code: code, name: name, id: id, logo: logo })
      );
    },
    getMySchools() {
      this.$store.dispatch("auth/getMySchools").then(
        (res) => {
          this.mySchoolsData = res.data;
        },
        (error) => {
          this.mySchoolsError = error.response.data.errors;
        }
      );
    },
  },
});
</script>
  
  <style lang="scss" scoped>
.school-choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    padding: 15% 0 20px 0;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.48px;
  }

  &__block-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 10% 0 0 0;
  }
  &__block {
    display: flex;
    justify-content: center;
    border-radius: 20px;
    border: 3px solid #06c;
    background: #d7e7f6;
    width: 285px;
    height: 160px;
    position: relative;
    margin: 10px;

    img {
      margin: 20px 10px;
      max-width: 213px;
      max-height: 51px;
      object-fit: contain;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
      transition: 0.3s;
    }
  }
  &__school-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
    background: #06c;
    position: absolute;
    bottom: 0;
    border-radius: 0 0 12px 12px;
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    letter-spacing: 0.54px;
    text-transform: uppercase;
  }
}

@media (max-width: 999px) {
  .school-choice {
    &__title {
      font-size: 16px;
      padding: 25px 0 15px 0;
    }
  }
}
</style>
  